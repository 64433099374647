.sap-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 5.5rem;

    border-radius: 1.5rem;
}

.sap-icon {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, -50%);
}

.sap-location {
    font-size: 2.5rem;
    color: #33058d;
}

.sap-logo {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.sap-container h4,
h3 {
    color: #33058d;
}

.mobile-gap {
    gap: 1rem;
}
