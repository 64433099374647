$primary: #33058d; //#80BD41;
$secondary: #9df894; //#127681;
$ternary: #facd90; //#10375c;
//#f4f6ff https://colorhunt.co/palette/189775
$text-primary: #353535; /*#062325;*/
$text-nonactive: #a8a8a8;
$text-navigation: white; /*#222;*/

//#08ccc9;
//#66CCCC
//#3C3C3C;

//#f3c623; puvodni zluta
//#93C21C; puvodni zelena

$primary-lighter2: lighten($primary, 2%); //#8cc354;

$pending-color: #facd90;
$declined-color: #ff637d;
$confirmed-color: #9df894;

/* SWAL */
$swal2-confirm-button-background-color: $primary;
$swal2-input-focus-border: 2px solid $primary;
@import "~sweetalert2/src/sweetalert2";

:export {
    colorPrimary: $primary;
    colorSecondary: $secondary;
    colorConfirmed: $confirmed-color;
    colorPending: $pending-color;
    colorDeclined: $declined-color;
    textPrimary: $text-primary;
}
