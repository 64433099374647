@import "styles/color-palette.scss";

@import "~bootstrap/scss/bootstrap";

body {
    margin: 0;
    padding: 0;
    font-family: "Work Sans", sans-serif;
    color: $text-primary;
    height: 100%;
    font-size: 14.4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Work Sans", sans-serif;
}

.parkfair-container {
    padding: 2rem 2rem;
}
.center-container {
    text-align: center;
    width: 80%;
    margin: auto;
}

.blue {
    color: $primary;
}

.benefits img {
    height: 6em;
}

/*
.dark-text {
  color: #3C3C3C;
}
.dark-text a{
 color: #3C3C3C;
}
.dark-text a:hover{
 color: #7C7C7C;
}*/

a:hover {
    color: $primary;
}
a.ui {
    text-decoration: none;
}
.delete.icon {
    background: none;
}

.how-it-works img {
    height: 18em;
}

.parking-terms img {
    height: 6em;
}

.bigger-text {
    font-size: 120%;
}
.box-center {
    width: 50%;
    margin: auto;
}
.content-left {
    text-align: left;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid $primary;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
}
.loader-small {
    border: 2px solid #f3f3f3;
    border-top: 2px solid $primary;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: auto;
}
.loader-day-picker {
    border: 3px solid #f3f3f3;
    border-top: 3px solid $primary;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: 150px;
    top: -10px;
}
.loader-small-dark {
    //Dark for Carpool, light for Garage
    border: 2px solid white;
    border-top: 2px solid $primary;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.fake-link {
    cursor: pointer;
    text-decoration: underline;
}
.fake-link:hover {
    cursor: pointer;
    opacity: 0.8;
}

div {
    white-space: pre-line;
}

hr {
    background-color: whitesmoke;
}

.reservations-panel {
    min-width: 100px;
}
.spotPopup {
    /*padding: 0 !important;*/
    width: 50em !important;
}
.swal2-close {
    color: $text-nonactive !important;
}
.swal2-close:hover {
    color: $primary !important;
}
.swal2-styled {
    min-width: 100%;
}
.swal2-actions {
    min-width: 40%;
}

.swal2-content {
    z-index: 2 !important;
}

@media only screen and (max-width: 768px) {
    .swal2-header,
    .swal2-content,
    .swal2-actions {
        padding: 0 !important;
    }
}
.swal2-container {
    z-index: 1250 !important;
}

.cancel-reservaton-container {
    min-height: 50vh;
}

.reservation-canceled_text {
    color: $text-nonactive !important;
}
.reservation-abused_text {
    color: $declined-color !important;
}

/*--------------------------FORMS--------------------------*/
input[type="text"].y-style,
input[type="email"].y-style,
input[type="password"].y-style,
input[type="tel"].y-style,
select.y-style,
textarea.y-style {
    border: 1px solid $primary;
    color: $text-primary;
    font-family: "Work Sans", sans-serif;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none; /*remove native browser styling*/
    -moz-appearance: none;
    appearance: none;
}

input.y-style:focus {
    outline-style: none;
}
select.y-style:focus {
    outline-style: none;
}
button:focus {
    outline-style: none;
}

.input-like {
    border: 1px solid $primary !important;
    color: $text-primary !important;
    font-family: "Work Sans", sans-serif !important;
    border-radius: 0.25rem !important;
}

.dark-text input[type="text"].y-style,
.dark-text input[type="email"].y-style,
.dark-text input[type="password"].y-style,
.dark-text input[type="tel"].y-style,
.dark-text select.y-style,
.dark-text textarea.y-style {
    border: 1px solid #3c3c3c;
    color: #3c3c3c;
}

.light-text {
    color: #aaa;
}

textarea {
    height: auto;
}

input.calendar {
    border: none;
    padding: 12px 10px;
    background-image: url("../assets/icons/calendar.svg");
    background-repeat: no-repeat;
    background-size: auto 40%;
    background-position: 97% center;
}

.dark-text input.calendar {
    background-image: url("../assets/icons/calendar-dark.svg");
}

select.select_arrow {
    /*width:100%;*/
    background-image: url("../assets/icons/drop-down.svg") !important;
    background-repeat: no-repeat !important;
    background-size: auto 50% !important;
    background-position: 97% center !important;
    padding-right: 2rem;
}

button[type="button"],
button[type="submit"],
a.button {
    font-family: "Work Sans", sans-serif;
}

.hm-btn {
    width: 200px;
}
.hm-btn:disabled {
    color: white;
}

button.btn-link {
    width: auto;
    color: $primary;
}

.modal-content button[type="button"] {
    width: auto;
}

label {
    color: $text-primary;
    font-family: "Work Sans", sans-serif;
    text-align: right;
}

.margin {
    margin: 2%;
}
.rdt {
    padding: 0;
} /*datetimepicker class */
.button-img {
    max-width: 25px;
    margin-left: -7px;
    padding: 3px;
    background: #fff;
    border-radius: 25px;
}

input.switch {
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    background-color: #fff;
    border: 1px solid $primary;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 #fff;
}
.dark-text input.switch {
    border-color: #3c3c3c;
}

input.switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.switch:checked {
    box-shadow: inset 20px 0 0 0 $primary;
    border-color: $primary;
}
.dark-text input.switch:checked {
    box-shadow: inset 20px 0px 0px 0px #e5e5e5;
    border-color: #3c3c3c;
}

input.switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cccccc;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #cccccc;
}

.error {
    color: red;
    white-space: pre-line;
}

.btn-link:hover {
    text-decoration: none;
}

.control-link {
    cursor: pointer;
    padding: 0;
}

.CookieConsent {
    z-index: 1300 !important;
}

.noMargins {
    margin-bottom: 0;
    padding-bottom: 0;
}

img.small {
    width: 25px;
}

.store-icon {
    max-width: 120px;
}

.tooltip-questionmark {
    width: 1.4em;
    height: 1.4em;
    background: $primary;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-left: 2px;
    color: white;
    font-size: 0.7em;
    vertical-align: top;
    padding-left: 1px;
}

.rs-picker-menu {
    z-index: 1507 !important;
}

.rs-picker {
    width: 100% !important;
}

/*--------------------------MEDIA QUERIES--------------------------*/
@media only screen and (max-width: 1200px) {
    .box-center {
        width: 60%;
    }
    .center-container {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .box-center {
        width: 80%;
    }
}

@media only screen and (max-width: 758px) {
    .box-center {
        width: 90%;
    }

    .parkfair-container {
        padding: 0.5rem 0.5rem;
    }

    label {
        text-align: center;
    }
    .hideMobile {
        display: none;
    }
    .navbar-brand {
        width: 70%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 992px) {
    input.switch {
        width: 35px;
        height: 20px;
    }
    input.switch:after {
        width: 17px;
        height: 17px;
    }
    input.switch:checked {
        box-shadow: inset 15px 0 0 0 $primary;
    }
}

@media only screen and (min-width: 576px) {
    .vertical-align {
        display: flex;
        align-items: center;
    }
}

/*-----------------CAPTION CONTAINER--------------*/
.caption-container {
    padding: 2rem 2rem;
}
@media only screen and (max-width: 768px) {
    .caption-container {
        padding: 0.5rem 0.5rem;
    }
}

@include media-breakpoint-up(md) {
    .container-small {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.456); // 350px;
    }

    .container-medium {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.586); // 450px;
    }

    .container-large {
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .container-small {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.554); // 550px;
    }

    .container-medium {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.757); // 750px;
    }

    .container-large {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.9);
    }
}

@include media-breakpoint-up(xl) {
    .container-small {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.625); // 750px;
    }

    .container-medium {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.875); // 1050px;
    }

    .container-large {
        width: calc(breakpoint-min(xl, $grid-breakpoints) * 0.875); // 1050px;
    }
}

.container-small {
    max-width: 100%;
    margin: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.container-medium {
    max-width: 100%;
    margin: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.container-large {
    max-width: 100%;
    margin: auto;
    padding-left: 1em;
    padding-right: 1em;
}
