@import "styles/color-palette.scss";

.navbar-dark .navbar-nav .nav-link {
    color: $text-navigation;
    padding: 0 1rem;
}
.navbar-dark .navbar-nav .nav-link:hover {
    color: $text-navigation;
    opacity: 0.5;
}
.navbar-dark .navbar-nav .show > .nav-link {
    color: $text-navigation;
}

.menu-item .nav-link {
    color: #fff;
    padding: 0rem;
}
.menu-item .nav-link:hover {
    color: #fff;
    padding: 0rem;
}

nav button[type="button"] {
    width: auto;
} /* hamburger menu button   */

.dropdown-menu {
    left: auto;
    right: 0;
    background: $primary;
}
.dropdown-menu a {
    color: #fff;
    font-size: 0.9rem;
}
.dropdown-item.active {
    color: $text-navigation;
    background-color: darken($primary, 5%);
}
.dropdown-item.active:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.logged-user {
    white-space: nowrap;
    margin-right: 30px;
} /*logged user name and picture in line (no line-break) for <li>*/
.small-img {
    width: 40px;
    padding: 0 5px;
    fill: currentColor;
} /*user image*/
.logo {
    width: 100%;
}
.navigation-logo {
    height: 32px;
    margin: 10px;
}

/*.dropdown-toggle::after {  hide arrow down from dropdown button
    display:none;
}*/

@media (min-width: 758px) {
    #root {
        display: flex;
    }

    #page-wrap {
        /*padding-top: 2rem;*/
        min-width: 0;
        flex: 1; /* take the whole space left */
    }
    #page-wrap-spacer {
        flex: 0 0 260px; /*do not grow, do not shrink, be 260 wide */
    }
}

@media only screen and (max-width: 758px) {
    #page-wrap {
    }
    #page-wrap-spacer {
        height: 52px;
        border-bottom: solid 2px $primary;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 10;
        transition: background-color 0.2s;
        text-align: right;
    }
    .page-wrap-spacer-solid {
        background-color: $primary;
    }
    .page-wrap-spacer-transparent {
        background-color: white;
    }
}
.menu-spacer {
    height: 52px;
}

.menu-section {
    flex: 0;
}
.logo-block {
    display: block;
}

.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    left: 26px;
    top: 16px;
    z-index: 1200 !important;
}
@media (min-width: 758px) {
    .bm-burger-button {
        display: none;
    }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    border-radius: 10px;
    transition: all 0.3s linear;
}
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: $text-primary;
    opacity: 1 !important;
}

.bm-burger-bars_open {
    background: white;
}
.bm-burger-bars_closed {
    background: $text-primary;
}

.bm-burger-bars_open:first-child {
    transform: translateY(8px) rotate(45deg);
}
.bm-burger-bars_closed:first-child {
    transform: translateY(0px) rotate(0);
}

.bm-burger-bars_open:nth-child(2) {
    opacity: 0 !important;
    transform: translateX(20px);
}
.bm-burger-bars_closed:nth-child(2) {
    opacity: 1 !important;
    transform: translateX(0px);
}

.bm-burger-bars_open:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}
.bm-burger-bars_closed:nth-child(3) {
    transform: translateY(0px) rotate(0deg);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: white;
}
@media (min-width: 758px) {
    .bm-cross {
        display: none;
    }
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    z-index: 1060 !important;
}

/* General sidebar styles */
.bm-menu {
    background: linear-gradient(70deg, $primary-lighter2 37%, $primary 37%);
    padding: 0.2em 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.3em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

.bm-item:focus {
    outline-style: none;
}

.menu-item {
    color: $text-navigation;
    padding: 0.4rem 1rem;
    display: block;
    text-decoration: none;
}

.menu-item:hover {
    color: $text-navigation;
    background-color: darken($primary, 5%);
    text-decoration: none;
    cursor: pointer;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
