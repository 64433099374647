@import "styles/color-palette.scss";

.rdp {
    --rdp-cell-size: 50px;
    --rdp-accent-color: #33058d;
    --rdp-background-color: #33058d;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */

    margin: 1em;
}

.rdp-day {
    margin: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 576px) {
    .rdp-day {
        padding: 0.47em;
        font-size: 0.65rem;
    }

    .rdp {
        --rdp-cell-size: 28px;
    }
}

.rdp-day:hover {
    background-color: $primary;
    color: rgb(255, 255, 255);
    /*box-shadow: 0px 0px 20px 0px lightgray;*/
}
.rdp-day_selected,
.rdp-day:focus {
    background-color: $primary;
    color: rgb(255, 255, 255);
}

/*Pending*/
.rdp-day--pending:hover {
    background-color: $pending-color !important;
}
.rdp-day--pending,
.rdp-day--pending:focus {
    border: solid;
    border-width: 0.2em;
    border-color: $pending-color !important;
}
.rdp-day_selected.rdp-day--pending,
.rdp-day.rdp-day--pending:focus {
    background-color: $pending-color !important;
}

/*Declined*/
.rdp-day--declined:hover {
    background-color: $declined-color !important;
}
.rdp-day--declined,
.rdp-day--declined:focus {
    border: solid;
    border-width: 0.2em;
    border-color: $declined-color !important;
}
.rdp-day_selected.rdp-day--declined,
.rdp-day.rdp-day--declined:focus {
    background-color: $declined-color !important;
}

/*Confirmed*/
.rdp-day--confirmed:hover {
    background-color: $confirmed-color !important;
}
.rdp-day--confirmed,
.rdp-day--confirmed:focus {
    border: solid;
    border-width: 0.2em;
    border-color: $confirmed-color !important;
}
.rdp-day_selected.rdp-day--confirmed,
.rdp-day.rdp-day--confirmed:focus {
    background-color: $confirmed-color !important;
}

.confirmed-color {
    color: $confirmed-color !important;
    transition: color 0.17s ease-in-out;
}

.pending-color {
    color: $pending-color !important;
    transition: color 0.17s ease-in-out;
}

.declined-color {
    color: $declined-color !important;
    transition: color 0.17s ease-in-out;
}

a.confirmed-color:hover,
button.confirmed-color:hover {
    color: darken($confirmed-color, 20%) !important;
    transition: color 0.17s ease-in-out;
}
a.pending-color:hover,
button.pending-color:hover {
    color: darken($pending-color, 20%) !important;
    transition: color 0.17s ease-in-out;
}
a.declined-color:hover,
button.declined-color:hover {
    color: darken($declined-color, 20%) !important;
    transition: color 0.17s ease-in-out;
}

.rdp-day--outside-range {
    color: lightgray;
}
